<template>
    <modal ref="modalDuplicarParametros" v-loading="loading" titulo="Duplicar los parámetros a otro cedis" @guardar="duplicar()">
        <div class="row mx-0 px-5 mb-4">
            <p class="col-12 px-0 f-15 text-general">
                Selecciona los perfiles que sea duplicar
            </p>
            <div class="col-12 px-0 mt-2 d-middle">
                <el-checkbox-group v-model="usuariosChecked">
                    <el-checkbox :label="1" class="check-dark">
                        {{$config.cliente}}
                    </el-checkbox>
                    <el-checkbox :label="2" class="check-dark">
                        {{$config.vendedor}}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="row mx-0 px-5 mb-3">
            <div class="col-12 px-0">
                Selecciona los cedis a los cuales se duplicará <br /> estos parámetros
            </div>
        </div>
        <div v-for="item in cedisFiltered" :key="item.id" class="row mx-0 px-5 mb-3">
            <el-checkbox-group v-model="cedisChecked">
                <el-checkbox :label="item.id">
                    <div class="col text-general f-15 f-400">
                        {{ item.nombre }}
                    </div>
                </el-checkbox>
            </el-checkbox-group>
        </div>
    </modal>
</template>

<script>
import visitasDiarias from '~/services/gaming/recompensas/visitasDiarias'

export default {
    props: {
        cedi: {
            type:[String, Number],
            default: 0,
        },
        tipo: {
            type:[String, Number],
            default: 1,
        },
    },
    data(){
        return {
            cedis: [],
            cedisChecked: [],
            usuariosChecked: [],
            loading: false
        }
    },
    computed: {
        cedisFiltered(){
            return this.cedis.filter(el => el.id != this.cedi)
        }
    },
    methods: {
        async toggle(){
            this.limpiar()
            await this.listarCedis()
            this.$refs.modalDuplicarParametros.toggle();
        },
        async listarCedis(){
            try {
                const { data } = await visitasDiarias.listarCedis();
                this.cedis = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async duplicar(){
            try {
                if(_.isEmpty(this.usuariosChecked)) return this.notificacion('', 'Debe seleccionar un perfil', 'error')
                if(_.isEmpty(this.cedisChecked)) return this.notificacion('', 'Debe seleccionar al menos un cedis', 'error')
                this.loading = true
                const params = {
                    cedi_desde: this.cedi,
                    tipo_desde: this.tipo,
                    cedis: this.cedisChecked,
                    usuarios: this.usuariosChecked,
                }


                const { data } = await visitasDiarias.duplicar(params)
                if (data.exito){

                    this.notificacion('', 'Duplicado correctamente', 'success')
                    this.$refs.modalDuplicarParametros.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false

            }
        },
        limpiar(){
            this.cedis = []
            this.cedisChecked = []
            this.usuariosChecked = []
        }

    }
}
</script>
